import React from "react";

import PageHeading from "../components/PageHeading";
import PageWrapper from "../components/PageWrapper";
import Imprint from "../components/Imprint";

export default () => {
  return (
    <>
      <PageHeading>Impressum</PageHeading>
      <PageWrapper>
        <Imprint />
      </PageWrapper>
    </>
  );
};
