import isCordova from "./isCordova";

export default () => {
  const FirebasePlugin = window.FirebasePlugin;

  if (isCordova) {
    console.log("running in cordova");

    FirebasePlugin.hasPermission(function (hasPermission) {
      if (hasPermission !== "granted") {
        FirebasePlugin.grantPermission(
          () => {
            console.log("Push Permission granted");
          },
          () => {
            console.log("Push Permission denied");
          }
        );
      }
    });

    FirebasePlugin.onTokenRefresh(
      (fcmToken) => {
        console.log("FCM-Token", fcmToken);
        // TODO: send Firebase Token to API
      },
      (error) => {
        console.error(error);
      }
    );
  }
};
