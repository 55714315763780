import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { motion } from "framer-motion";

import AgendaIcon from "./icons/Agenda";
import NewsIcon from "./icons/News";
import FeedbackIcon from "./icons/Feedback";
import ProfileIcon from "./icons/Profile";

const Wrapper = styled(motion.div)`
  z-index: 10;
  height: 67px;
  border-radius: 33.5px;
  width: calc(100% - 20px);
  position: fixed;
  bottom: ${() => (isIOS ? "max(10px, env(safe-area-inset-bottom))" : "10px")};
  left: 50%;
  transform: translateX(-50%);
  background: ${(props) => props.theme.colors.secondary02};
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  box-shadow: ${(props) => props.theme.shadows.navigation};
`;

const StyledLink = styled(NavLink)`
  padding: 0.5rem;
  width: 32px;
  color: ${(props) => props.theme.colors.primary04};
  -webkit-tap-highlight-color: transparent;
  &.is-active,
  &:active {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default () => {
  return (
    <Wrapper
      initial={{ y: "100%", x: "-50%" }}
      animate={{ y: 0, x: "-50%" }}
      exit={{ y: "100%" }}
    >
      <StyledLink to="/agenda" activeClassName="is-active">
        <AgendaIcon />
      </StyledLink>
      <StyledLink to="/news" activeClassName="is-active">
        <NewsIcon />
      </StyledLink>
      <StyledLink to="/feedback" activeClassName="is-active">
        <FeedbackIcon />
      </StyledLink>
      <StyledLink to="/me" activeClassName="is-active">
        <ProfileIcon />
      </StyledLink>
    </Wrapper>
  );
};
