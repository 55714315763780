import React, { useState } from "react";

import LoginForm from "../components/LoginForm";
import client from "../util/apolloClient";
import { gql } from "apollo-boost";
import LoadingIndicator from "../components/LoadingIndicator";
// import loginQuery from "./../queries/loginQuery";

const loginQuery = gql`
  query($username: Int!, $password: String!) {
    login(matrikelnum: $username, password: $password) {
      userId
      token
      tokenExpiration
    }
  }
`;

export default ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (event) => {
    const { username, password, e } = event;
    e.preventDefault();
    client
      .query({
        query: loginQuery,
        variables: { username: parseInt(username), password },
      })
      .then((result) => {
        onLogin(result.data.login);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage(
          'Deine Matrikelnummer oder das Passwort ist ungültig. Bitte gebe die richtige Matrikelnummer sowie Passwort ein. Wenn du dein Passwort vergessen hast, kannst du dieses über den Link unter den Eingabefeldern zurücksetzen. Falls du die App zum ersten Mal nutzt wechsle in den "Registrieren" Tab.'
        );
        console.error(e);
      });
    setLoading(true);
  };
  return (
    <>
      {!loading && (
        <LoginForm errorMessage={errorMessage} onSubmit={onSubmit} />
      )}
      {loading && <LoadingIndicator delay={0} />}
    </>
  );
};
