import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { transparentize } from "polished";

import PageWrapper from "../components/PageWrapper";
import SettingsIcon from "../components/icons/Settings";

const BackgroundCover = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  height: calc(100% - 500px);
  width: 100%;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 500px;
`;

const ProfileImage = styled.img`
  object-fit: cover;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
`;

const ProfileImageWrapper = styled.div`
  height: 500px;
  width: calc(100% - 16px);
  max-width: ${(props) => props.theme.sizes.maxLayoutWidth};
  position: fixed;
  top: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
`;

const SettingsButtonWrapper = styled(Link)`
  display: block;
  color: ${(props) => props.theme.colors.white};
  z-index: 3;
  position: fixed;
  right: 16px;
  top: 32px;
  opacity: 0.5;
  -webkit-tap-highlight-color: transparent;
`;

const StudentId = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondary03};
  padding: 16px;
  border-radius: ${(props) => props.theme.borders.radius01};
  z-index: 5;
`;

const StudentName = styled.h2`
  font-weight: 300;
  margin: 0;
`;

const StudentMail = styled.p`
  margin-top: 0;
  margin-bottom: 16px;
  color: ${(props) => props.theme.colors.primary02};
  line-height: 1;
`;

const StudentIdData = styled.td`
  padding-left: 16px;
  color: ${(props) => props.theme.colors.primary02};
`;

const StatisticWrapper = styled.div`
  margin-left: -10px;
  margin-right: -8px;
  display: flex;
  justify-content: space-around;
  z-index: 6;
  position: relative;
`;

const Statistic = styled.div`
  background: ${(props) => props.theme.colors.secondary03};
  border-radius: ${(props) => props.theme.borders.radius01};
  width: 100px;
  height: 100px;
  font-size: 10px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BigText = styled.span`
  font-size: 30px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.primary};
`;

const SmallText = styled.div`
  font-size: 10px;
  font-weight: 700;
  padding-top: 15px;
  color: ${(props) => props.theme.colors.primary02};
`;

const DescriptionWrapper = styled.div`
  z-index: 7;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Description = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100px;
  justify-content: center;

  display: flex;
`;

const SpacerTop = styled.div`
  width: 100%;
  height: 335px;
`;

const ModulesWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondary03};
  margin-top: 35px;
  border-radius: 16px;
  z-index: 8;
  padding: 16px 16px 1px 16px;
`;

const Semester = styled.div`
  font-size: 17px;
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 700;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Module = styled.div`
  display: block;
  background: ${(props) => props.theme.colors.secondary04};
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const ModuleDescription = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const Grade = styled.div`
  font-weight: 700;
  align-self: center;
  text-align: right;
`;

const GradeDescription = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  line-height: 1;
  font-size: 10px;
`;

const ImageGradient = styled.div`
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.colors.secondary} 0%,
    ${(props) => transparentize(1, props.theme.colors.secondary)} 100%
  );
  width: 100%;
  height: 100px;
  top: 400px;
  left: 0;
  z-index: 5;
  position: absolute;
`;

export default ({ user, dualis }) => {
  if (!user || !dualis) {
    return (
      <PageWrapper>
        <ProfileImageWrapper>
          <SettingsButtonWrapper to="/me/settings">
            <SettingsIcon />
          </SettingsButtonWrapper>
        </ProfileImageWrapper>
      </PageWrapper>
    );
  }

  const { vorname, nachname, picurl, email, studiengang, matrikelnum } = user;
  const name = vorname + " " + nachname;

  const { moduleects, modulenotes, modules } = dualis;

  return (
    <PageWrapper>
      <ProfileImageWrapper>
        <ProfileImage src={picurl} />
        <SettingsButtonWrapper to="/me/settings">
          <SettingsIcon />
        </SettingsButtonWrapper>
      </ProfileImageWrapper>
      <SpacerTop />
      <ImageGradient />
      <BackgroundCover className={"bg-cover"} />
      <StudentId>
        <StudentName>{name}</StudentName>
        <StudentMail>{email}</StudentMail>
        <table>
          <tbody>
            <tr>
              <td>Matrikel-Nr:</td>
              <StudentIdData>{matrikelnum}</StudentIdData>
            </tr>
            <tr>
              <td>Uni-ID:</td>
              <StudentIdData>cp899</StudentIdData>
            </tr>
            <tr>
              <td>Kurs:</td>
              <StudentIdData>{studiengang}</StudentIdData>
            </tr>
            <tr>
              <td>Studienzeit:</td>
              <StudentIdData>01.10.2017 - 30.09.2020</StudentIdData>
            </tr>
          </tbody>
        </table>
      </StudentId>
      <StatisticWrapper>
        <Statistic>
          <BigText>1,3</BigText>
          <SmallText>/2,1</SmallText>
        </Statistic>
        <Statistic>
          <BigText>152</BigText>
          <SmallText>/210</SmallText>
        </Statistic>
        <Statistic>
          <BigText>124</BigText>
          <SmallText>Tage</SmallText>
        </Statistic>
      </StatisticWrapper>
      <DescriptionWrapper>
        <Description>Durchschnitt</Description>
        <Description>ECTS</Description>
        <Description>Semesterende</Description>
      </DescriptionWrapper>
      <ModulesWrapper>
        <Semester>WiSe 2019/2020</Semester>
        {modules.map((module, index) => {
          return (
            <Module key={index}>
              <ModuleDescription>{module}</ModuleDescription>
              <Grade>
                {modulenotes[index]}
                <GradeDescription>{moduleects[index]}</GradeDescription>
              </Grade>
            </Module>
          );
        })}
      </ModulesWrapper>
    </PageWrapper>
  );
};
