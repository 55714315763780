import React from "react";
import { Route } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { defaultPollInterval } from "../util/apolloClient";
import profileQuery from "../util/queries/profile";
import AnimatePresence from "../util/AnimatePresence";

import UserProfile from "../components/UserProfile";
import Settings from "../components/Settings";
import Imprint from "../components/Imprint";
import Modal from "../components/Modal";
import ModalHeading from "../components/ModalHeading";

export default ({ onLogout, onThemeChange, onFetchError }) => {
  const { loading, error, data } = useQuery(profileQuery, {
    pollInterval: defaultPollInterval,
    onError: onFetchError,
  });

  let user, dualis;
  if (data) {
    user = data.getMyProfile.user;
    dualis = data.getMyProfile.dualis;
  }

  return (
    <>
      <UserProfile
        user={user}
        dualis={dualis}
        onLogout={onLogout}
        onThemeChange={onThemeChange}
      />
      <AnimatePresence>
        <Route path="/me/settings*">
          <Settings
            user={user}
            onLogout={onLogout}
            onThemeChange={onThemeChange}
          />
        </Route>
        <Route path="/me/settings/imprint">
          <Modal modalId="settings" closeButtonTo="settings">
            <ModalHeading title="Impressum" />
            <Imprint />
          </Modal>
        </Route>
      </AnimatePresence>
    </>
  );
};
