import React from "react";
import { Route } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import { defaultPollInterval } from "../util/apolloClient";
import AnimatePresence from "../util/AnimatePresence";

import Feedback from "../components/Feedback";
import LecturerDetail from "../pages/LecturerDetail";
import FeedbackDialog from "../pages/FeedbackDialog";
import PageHeading from "../components/PageHeading";
import SearchIcon from "../components/icons/Search";
import LoadingIndicator from "../components/LoadingIndicator";

const lecturerQuery = gql`
  query {
    getMyTutors {
      tutors {
        id
        tutorrolle
        studiengang
        anrede
        vorname
        nachname
        email
        tel
        web
        company
        picurl
      }
      feedbacks {
        tutor
        feedback
        isAnswered
      }
    }
  }
`;

export default ({ onFetchError }) => {
  const { loading, error, data, refetch } = useQuery(lecturerQuery, {
    pollInterval: defaultPollInterval,
    onError: onFetchError,
  });

  const Heading = <PageHeading Button={SearchIcon}>Meine Dozenten</PageHeading>;

  if (loading)
    return (
      <>
        {Heading} <LoadingIndicator />
      </>
    );

  if (error) {
    console.error(error);
    return <>{Heading} Laden der Daten ist fehlgeschlagen</>;
  }

  const lecturers = data.getMyTutors.tutors.sort((a, b) => {
    if (a.nachname > b.nachname) return 1;
    if (b.nachname > a.nachname) return -1;
    return 0;
  });

  const feedbacks = data.getMyTutors.feedbacks;

  for (const lecturer of lecturers) {
    lecturer.hasUnansweredFeedback = false;
    const feedback = feedbacks.find(
      (feedback) => lecturer.id === feedback.tutor
    );
    if (!!feedback) {
      lecturer.hasUnansweredFeedback = !feedback.isAnswered;
    }
  }

  return (
    <>
      {Heading}
      <Feedback allLecturers={lecturers} />
      <AnimatePresence>
        <Route path="/feedback/lecturer/:lecturerId">
          <LecturerDetail allLecturers={lecturers} />
        </Route>
        <Route path="/feedback/lecturer/:lecturerId/feedback">
          <FeedbackDialog onSubmitFeedback={refetch} allLecturers={lecturers} />
        </Route>
      </AnimatePresence>
    </>
  );
};
