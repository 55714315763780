import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath>
          <path
            d="M1.41 0L0 1.41 4.58 6 0 10.59 1.41 12l6-6z"
            data-name="↳Color"
            transform="translate(8.59 6)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Gruppe 1">
        <path
          fill="currentColor"
          d="M1.41 0L0 1.41 4.58 6 0 10.59 1.41 12l6-6z"
          data-name="↳Color"
          transform="translate(8.59 6)"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
