import React from "react";
import styled from "styled-components";

import NewsPageWrapper from "../components/NewsPageWrapper";
import CopyToCliboardButton from "../components/CopyToClipboardButton";
import moment from "moment";

const NewsWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondary03};
  padding: 0 16px 35px 16px;
  border-radius: ${(props) => props.theme.borders.radius01};
`;

const NewsHeader = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary02};
  padding: 16px 0;
`;

const PublicationWrapper = styled.div`
  font-size: 10px;
  padding-top: 12px;
  position: absolute;
  display: inline-block;
  right: 16px;
  color: ${(props) => props.theme.colors.primary02};
`;

const NewsDescription = styled.div`
  background: ${(props) => props.theme.colors.secondary04};
  border-radius: ${(props) => props.theme.borders.radius01};
`;

const EditorName = styled.div`
  position: relative;
  display: inline-block;
  left: 10px;
  top: -18px;
`;

const EditorImage = styled.img`
  border-radius: 100%;
  object-fit: cover;
  width: 45px;
  height: 45px;
  border: 2px solid ${(props) => props.theme.colors.primary};
`;

const NewsImage = styled.img`
  border-top-left-radius: ${(props) => props.theme.borders.radius01};
  border-top-right-radius: ${(props) => props.theme.borders.radius01};
  object-fit: cover;
  width: 100%;
  height: 200px;
`;

const NewsText = styled.div`
  padding: 8px 16px 16px 16px;
`;

export default ({ authorName, authorPicurl, date, content, picurl }) => {
  const formattedDate = moment.unix(date).format("DD.MM.YYYY - HH:mm [Uhr]");
  const strippedContent = content.replace(/(<([^>]+)>)/gi, "");
  return (
    <NewsPageWrapper>
      <NewsWrapper>
        <CopyToCliboardButton
          text={`${authorName} (${formattedDate}):
${strippedContent}`}
        />
        <NewsHeader>
          <EditorImage src={authorPicurl} alt="Profilbild" />
          <EditorName>{authorName}</EditorName>
        </NewsHeader>
        <NewsDescription>
          <NewsImage src={picurl} alt="Post Thumbnail" />
          <NewsText dangerouslySetInnerHTML={{ __html: content }} />
        </NewsDescription>
        <PublicationWrapper>{formattedDate}</PublicationWrapper>
      </NewsWrapper>
    </NewsPageWrapper>
  );
};
