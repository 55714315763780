import React from "react";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      zIndex={200}
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default ({ onChange, min, max, defaultValue, value }) => {
  const theme = useContext(ThemeContext);
  return (
    <Slider
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      min={min}
      max={max}
      trackStyle={{ backgroundColor: theme.colors.accent, height: 4, top: 8 }}
      handleStyle={{
        borderColor: "transparent",
        height: 22,
        width: 22,
        backgroundColor: theme.colors.accent,
      }}
      handle={handle}
      railStyle={{
        backgroundColor: theme.colors.secondary02,
        height: 4,
        top: 8,
      }}
    />
  );
};
