import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ImprintWrapper = styled.div`
  padding: 8px 32px 0px 32px; 
`;

const ImprintText = styled.div`
  line-height: 1.25; 
  scroll-behavior: none; 
  overflow: hidden;
  position: fixed; 
`;

const ImprintHeadline = styled.h3``;

const Button = styled.span`
  color: ${(props) => props.theme.colors.primary};
  border-radius: 25px;
  font-size: 16px;
  background: ${(props) => props.theme.colors.accent};
  text-transform: uppercase;
  font-weight: 800;
  padding: 16px 64px 16px 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 0;
  bottom: -415px;
`;

export default () => {
  const history = useHistory();
  return (
    <ImprintWrapper>
      <ImprintText>
        Angaben gemäß § 5 TMG
      <br /><br />
        Studi-App <br />
        Lohrtalweg 10 <br />
        74821 Mosbach
      <ImprintHeadline>Vertreten durch:</ImprintHeadline>
        Fabio Jock
      <ImprintHeadline>Kontakt:</ImprintHeadline>
        Telefon: 015726789038<br />
        E-Mail: mail@studi-app.de
      <ImprintHeadline>Registereintrag:</ImprintHeadline>
        Eintragung im Registergericht Mosbach<br />
        Registernummer: 12345
      <ImprintHeadline>Umsatzsteuer-ID:</ImprintHeadline>
        2818273<br />
      </ImprintText>
      <Button>
        <span onClick={history.goBack}>zurück</span>
      </Button>
    </ImprintWrapper>
  );
};
