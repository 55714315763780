import { gql } from "apollo-boost";

export default gql`
  query($newstype: String!) {
    getAllNews(newstype: $newstype) {
      id
      content
      newstype
      date
      authorName
      authorPicurl
      picurl
    }
  }
`;
