import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import queryString from "query-string";
import moment from "moment";
import { useLocation } from "react-router-dom";

import {
  matchEventsToDays,
  calculateProgress,
} from "../util/helpers/dateHelpers";
import { defaultPollInterval } from "../util/apolloClient";

import PageWrapper from "../components/PageWrapper";
import AgendaDay from "../components/AgendaDay";
import AgendaEvent from "../components/AgendaEvent";
import LoadingIndicator from "../components/LoadingIndicator";

const agendaQuery = gql`
  query($date: Int!) {
    getAllLectures(date: $date) {
      lecture {
        id
        name
        room
        startdate
        enddate
      }
      dozent {
        vorname
        nachname
      }
    }
  }
`;

export default ({ onFetchError }) => {
  const location = useLocation();

  const onQueryCompleted = (data) => {
    const parsedSearchQuery = queryString.parse(location.search);
    const { scrollTo } = parsedSearchQuery;
    if (!scrollTo) {
      return;
    }
    const dateRef = document.querySelector(`.agenda-day-${scrollTo}`);
    if (!dateRef) {
      return;
    }
    dateRef.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const { loading, error, data } = useQuery(agendaQuery, {
    variables: { date: moment().startOf("day").unix() },
    onCompleted: onQueryCompleted,
    onError: onFetchError,
    pollInterval: defaultPollInterval,
  });

  if (!data && loading) {
    return (
      <PageWrapper>
        <LoadingIndicator />
      </PageWrapper>
    );
  }
  if (error) {
    return <PageWrapper>Fehler beim Abrufen der Daten</PageWrapper>;
  }

  const { getAllLectures } = data;

  const lectureDays = matchEventsToDays({ allEvents: getAllLectures });

  if (lectureDays.length === 0) {
    if (error) {
      return <PageWrapper>Fehler beim Abrufen der Daten</PageWrapper>;
    }
    return <PageWrapper>Keine Vorlesungen gefunden.</PageWrapper>;
  }

  return (
    <PageWrapper>
      {lectureDays.map((lectureDay, index) => (
        <AgendaDay key={lectureDay.date.format()} dateObj={lectureDay.date}>
          {lectureDay.lectures.map((lectureObject, lectureIndex) => {
            const { lecture, dozent } = lectureObject;
            const { id, name, room, startdate, enddate } = lecture;
            const { vorname, nachname } = dozent;
            const dozentname =
              vorname.charAt(0).toUpperCase() + ". " + nachname;

            return (
              <AgendaEvent
                key={`${id}-${lectureIndex}`}
                eventId={id}
                title={name}
                room={room}
                lecturer={dozentname}
                startDate={moment.unix(startdate)}
                endDate={moment.unix(enddate)}
                progress={calculateProgress(
                  moment.unix(startdate),
                  moment.unix(enddate)
                )}
              />
            );
          })}
        </AgendaDay>
      ))}
    </PageWrapper>
  );
};
