import React from "react";
import NewsPostFood from "../components/NewsPostFood";

export default ({ newsArticles }) => {
  if (!newsArticles || newsArticles.length === 0) {
    return <div>keine Artikel vorhanden</div>;
  }
  newsArticles = newsArticles.sort((a, b) => a.date - b.date);
  return (
    <>
      {newsArticles.map((post, index) => {
        return <NewsPostFood key={index} {...post} />;
      })}
    </>
  );
};
