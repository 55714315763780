import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Logo from "./icons/LogoLarge";

const Wrapper = styled(Link)`
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
  position: relative;
  margin: 6rem auto 3rem auto;
  -webkit-tap-highlight-color: transparent;
  color: ${(props) => props.theme.colors.primary};
`;

function Header() {
  return (
    <Wrapper to="/">
      <Logo />
    </Wrapper>
  );
}

export default Header;
