import React, { useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import CloseIcon from "../components/icons/Close";
import { transparentize } from "polished";
import { motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;

const BackgroundWrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.layer === 3 ? "29" : "19")};
`;

const Background = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => transparentize(0.2, props.theme.colors.secondary)};
  z-index: ${(props) => (props.layer === 3 ? "29" : "19")};
  backdrop-filter: blur(4px);
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled(motion.div)`
  box-sizing: border-box;
  height: calc(100% - 100px);
  width: 100%;
  background: ${(props) => props.theme.colors.secondary03};
  position: fixed;
  left: 50%;
  transform: ${(props) =>
    props.recessed ? "translate(-50%, -48px) scale(0.9) " : "translateX(-50%)"};
  bottom: 0;
  z-index: ${(props) => (props.layer === 3 ? "30" : "20")};
  border-top-left-radius: ${(props) => props.theme.borders.radius02};
  border-top-right-radius: ${(props) => props.theme.borders.radius02};
  max-width: ${(props) => props.theme.sizes.maxLayoutWidth};
  box-shadow: ${(props) => props.theme.shadows.card02};
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1.5rem;
`;

const CloseButton = styled(Link)`
  color: ${(props) => props.theme.colors.secondary03};
  background: ${(props) => props.theme.colors.primary03};
  display: block;
  position: absolute;
  right: 1rem;
  top: 1.8rem;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  z-index: 10;
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 10px;
`;

const CloseIconWrapper = styled.div`
  height: 13px;
  width: 13px;
  position: absolute;
  left: 8px;
  top: 6px;
`;

export default ({
  children,
  closeButtonTo,
  recessed,
  layer,
  modalId,
  enableScaling,
}) => {
  const wrapperAnimate = { y: 0, x: "-50%" };
  if (recessed && enableScaling) {
    wrapperAnimate.scale = 0.9;
  }

  return (
    <>
      <GlobalStyle />
      <BackgroundWrapper
        key={"modal-bg" + modalId}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Background to={closeButtonTo} layer={layer} />
      </BackgroundWrapper>
      <Wrapper
        key={modalId}
        initial={{ y: "100%", x: "-50%" }}
        animate={wrapperAnimate}
        exit={{ y: "100%" }}
        recessed={recessed}
        layer={layer}
      >
        <CloseButton to={closeButtonTo}>
          <CloseIconWrapper>
            <StyledCloseIcon />
          </CloseIconWrapper>
        </CloseButton>
        <InnerWrapper>{children}</InnerWrapper>
      </Wrapper>
    </>
  );
};
