import moment from "moment";

const getNextMonths = ({ monthsInAdvace = 6, startDate = new Date() } = {}) => {
  startDate = moment(startDate);

  const months = [];
  for (let addMonths = 0; addMonths < monthsInAdvace; addMonths++) {
    const nextDate = startDate.clone().startOf("month").add(addMonths, "month");

    months.push({
      date: nextDate,
      readableDate: nextDate.toString(),
      month: nextDate.get("month"),
      year: nextDate.get("year"),
    });
  }

  months.map((month) => {
    const firstDayOfMonth = month.date.startOf("month");
    const allDays = [];
    for (
      let dayIndex = 0;
      dayIndex < firstDayOfMonth.daysInMonth();
      dayIndex++
    ) {
      const dayInMonth = firstDayOfMonth.clone().add(dayIndex, "day");
      allDays.push({
        date: dayInMonth,
        readableDate: dayInMonth.toString(),
        month: dayInMonth.get("month"),
      });
    }

    month.days = allDays;
    return month;
  });
  return months;
};

const matchEventsToDays = ({ allEvents }) => {
  let lectureDays = [];

  allEvents.sort((a, b) => a.startdate - b.startdate);

  for (const lectureObject of allEvents) {
    const startdate = moment.unix(lectureObject.lecture.startdate);
    const dayObject = startdate.startOf("day");
    const matchingDay = lectureDays.find((el) => el.date.isSame(dayObject));

    if (matchingDay) {
      matchingDay.lectures.push(lectureObject);
    } else {
      lectureDays.push({
        date: dayObject,
        readableDate: dayObject.format(),
        lectures: [lectureObject],
      });
    }
  }

  lectureDays = lectureDays.filter((el) =>
    el.date.isSameOrAfter(moment(), "day")
  );
  lectureDays = lectureDays.sort((a, b) => a.date.unix() - b.date.unix());

  return lectureDays;
};

const calculateProgress = (startDate, endDate) => {
  if (
    endDate.isSameOrBefore(startDate) ||
    !moment().isBetween(startDate, endDate)
  ) {
    return false;
  }

  const totalDuration = endDate.unix() - startDate.unix();
  const durationSinceStart = moment().unix() - startDate.unix();
  return durationSinceStart / totalDuration;
};

export { getNextMonths, matchEventsToDays, calculateProgress };
