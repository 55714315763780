import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  text-align: center;
`;

const CancelButton = styled(Link)`
  color: ${(props) => props.theme.colors.primary02};
  font-size: 10pt;
  text-align: left; 
  margin: 1rem; 
`;

export default () => {
  return (
    <Wrapper>
      <p>
        Bitte klicke den Link in der E-Mail, den wir an deine
        Studenten-Mail-Adresse geschickt haben.
      </p>
      <br />
      <CancelButton to="/">zum Login</CancelButton>
    </Wrapper>
  );
};
