import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { transparentize } from "polished";

import ImageWrapper from "../components/ImageWrapper";

const AvatarImage = styled(ImageWrapper)`
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: 8;
`;

const ImageGradient = styled.div`
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.colors.secondary03} 0%,
    ${(props) => transparentize(1, props.theme.colors.secondary03)} 100%
  );
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
`;

const LecturerInfoWrapper = styled.div`
  background: ${(props) => props.theme.colors.secondary05};
  position: relative;
  z-index: 9;
  border-radius: ${(props) => props.theme.borders.radius02};
  margin: 320px 12px 0;
  padding: 20px 24px;
`;

const FeedbackButton = styled(Link)`
  display: block;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  position: absolute;
  bottom: 24px;
  padding: 14px 48px;
  left: 50%;
  transform: translateX(-50%);
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.accent};
  font-weight: bold;
  border-radius: 24px;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Subtitle = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary05};
`;

const LecturerData = styled.table`
  margin-top: 16px;
`;

const BaseTableCell = styled.td`
  padding: 0;
`;

const TableCellKey = styled(BaseTableCell)`
  padding-right: 12px;
  font-weight: bold;
`;

const TableCellValue = styled(BaseTableCell)`
  color: ${(props) => props.theme.colors.primary05};
`;

const TableCellLink = styled.a`
  color: ${(props) => props.theme.colors.primary05};
  text-decoration: none;
`;

export default ({ lecturer }) => {
  const {
    picurl,
    vorname,
    nachname,
    id,
    email,
    tel,
    web,
    tutorrolle,
    hasFeedback,
  } = lecturer;

  const name = vorname + " " + nachname;
  const [role] = tutorrolle;
  return (
    <>
      <ImageContainer>
        <AvatarImage source={picurl} />
        <ImageGradient />
      </ImageContainer>
      <LecturerInfoWrapper>
        <Title>{name}</Title>
        {role && <Subtitle>{role}</Subtitle>}
        <LecturerData>
          <tbody>
            <tr>
              <TableCellKey>Mail:</TableCellKey>
              <TableCellValue>
                <TableCellLink href={`mailto:${email}`}>{email}</TableCellLink>
              </TableCellValue>
            </tr>
            <tr>
              <TableCellKey>Telefon:</TableCellKey>
              <TableCellValue>
                <TableCellLink href={`tel:${tel}`}>{tel}</TableCellLink>
              </TableCellValue>
            </tr>
            {!!web && web.length > 0 && (
              <tr>
                <TableCellKey>Website:</TableCellKey>
                <TableCellValue>
                  <TableCellLink href={`https://${web}`}>{web}</TableCellLink>
                </TableCellValue>
              </tr>
            )}
          </tbody>
        </LecturerData>
      </LecturerInfoWrapper>
      {hasFeedback && (
        <FeedbackButton to={`/feedback/lecturer/${id}/feedback`}>
          Feedback
        </FeedbackButton>
      )}
    </>
  );
};
