import React, { useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import queryString from "query-string";
import { gql } from "apollo-boost";

import client from "../util/apolloClient";

import ForgotPassword1 from "../components/ForgotPassword-step1";
import ForgotPassword2 from "../components/ForgotPassword-step2";
import ForgotPassword3 from "../components/ForgotPassword-step3";
import LoadingIndicator from "../components/LoadingIndicator";

const resetPasswordQuery = gql`
  query($username: Int!, $baseUrl: String!) {
    requestPwReset(matrikelnum: $username, url: $baseUrl)
  }
`;

const changePasswordMutation = gql`
  mutation ChangeUserPw($token: String!, $password: String!) {
    changeUserPw(hash: $token, newpassword: $password)
  }
`;

export default () => {
  const location = useLocation("/auth/:loginVariant");
  const [loading, setLoading] = useState(false);
  const [setErrorMessage] = useState(null);

  const history = useHistory();

  const parsedSearchQuery = queryString.parse(location.search);

  const { step, token } = parsedSearchQuery;

  const onSubmitStep1 = (e) => {
    const { username } = e;

    client
      .query({
        query: resetPasswordQuery,
        variables: {
          username: parseInt(username),
          baseUrl: `${window.location.origin}/#/auth/forgot-password?step=3&token=`,
        },
      })
      .then((result) => {
        setLoading(false);
        history.push("?step=2");
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage("Etwas ist schiefgelaufen.");
        console.error(e);
      });
    setLoading(true);
  };

  const onSubmitStep3 = (e) => {
    const { password } = e;
    client
      .mutate({
        mutation: changePasswordMutation,
        variables: {
          password,
          token,
        },
      })
      .then((result) => {
        setLoading(false);
        alert("Dein Passwort wurde erfolgreich zurückgesetzt.");
        history.push("/");
      })
      .catch((e) => {
        setLoading(false);
        alert(
          "Ein Fehler beim Zurücksetzen des Passworts ist aufgetreten. Bitte versuche es nocheinmal."
        );
        console.error(e);
      });
    setLoading(true);
  };

  if (loading) return <LoadingIndicator />;

  switch (step) {
    case "1":
      return <ForgotPassword1 onSubmit={onSubmitStep1} />;

    case "2":
      return <ForgotPassword2 />;

    case "3":
      return <ForgotPassword3 onSubmit={onSubmitStep3} />;

    default:
      return (
        <Redirect to={{ pathname: location.pathname, search: "?step=1" }} />
      );
  }
};
