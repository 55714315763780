import React from "react";
import Moment from "react-moment";
import styled from "styled-components";

const Text = styled.text`
  fill: ${(props) => props.theme.colors.secondary02};
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 47px;
`;

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <path
        fill="currentColor"
        d="M75 7h9a2 2 0 012 2v76a2 2 0 01-2 2H10a2 2 0 01-2-2V9a2 2 0 012-2h10.649v12.333a1 1 0 001 1h4.324a1 1 0 001-1V7h5.216v12.333a1 1 0 001 1h4.325a1 1 0 001-1V7h6.324v12.333a1 1 0 001 1h4.324a1 1 0 001-1V7H57v12a1 1 0 001 1h4a1 1 0 001-1V7h6v12a1 1 0 001 1h4a1 1 0 001-1V7z"
      ></path>
      <Text>
        <tspan x="47" y="68" textAnchor="middle">
          <Moment format="D" interval={60 * 1000} element="tspan" />
        </tspan>
      </Text>
    </svg>
  );
}

export default Icon;
