import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  padding: 8px;
  box-sizing: border-box;
`;

const Tab = styled(NavLink)`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  opacity: 0.4;
  font-weight: 700;
  font-size: 17px;
  padding: 4px;
  -webkit-tap-highlight-color: transparent;
  &.active {
    border-bottom-color: ${(props) => props.theme.colors.primary};
    opacity: 1;
  }
  &:only-child {
    margin: 0 48px;
  }
`;

export default ({ pages }) => {
  return (
    <Wrapper>
      {pages.map((page) => (
        <Tab activeClassName="active" to={page.to} key={page.to}>
          {page.text}
        </Tab>
      ))}
    </Wrapper>
  );
};
