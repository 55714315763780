import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g fill="currentColor" fillRule="nonzero" transform="translate(-373 -43)">
        <g transform="translate(373 43)">
          <path d="M22.698 9.414h-.53c-.173-.54-.39-1.064-.65-1.567l.375-.376c.911-.91.887-2.37 0-3.255l-1.109-1.11a2.301 2.301 0 00-3.255 0l-.376.377a10.102 10.102 0 00-1.567-.65V2.3A2.304 2.304 0 0013.284 0h-1.569a2.304 2.304 0 00-2.301 2.301v.531c-.54.173-1.064.39-1.567.65l-.376-.375a2.301 2.301 0 00-3.255 0l-1.11 1.109a2.301 2.301 0 000 3.255l.377.376c-.26.503-.478 1.028-.65 1.567H2.3A2.304 2.304 0 000 11.715v1.57a2.304 2.304 0 002.301 2.3h.531c.173.54.39 1.065.65 1.568l-.375.376a2.301 2.301 0 000 3.255l1.109 1.11a2.301 2.301 0 003.255 0l.376-.377c.503.26 1.028.478 1.567.65v.532A2.304 2.304 0 0011.716 25h1.568a2.304 2.304 0 002.302-2.301v-.531c.54-.173 1.064-.39 1.567-.65l.376.375c.909.91 2.368.889 3.255 0l1.11-1.109a2.301 2.301 0 000-3.255l-.377-.376c.26-.503.478-1.028.65-1.567h.532A2.304 2.304 0 0025 13.284v-1.569a2.304 2.304 0 00-2.302-2.3zM12.5 18A5.506 5.506 0 017 12.5C7 9.467 9.467 7 12.5 7S18 9.467 18 12.5 15.533 18 12.5 18z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
