import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.secondary03};
  border-radius: ${(props) => props.theme.borders.radius01};
  box-shadow: ${(props) => props.theme.shadows.card01};
  padding: 14px;
  margin-bottom: 18px;
`;

const DateLabel = styled.div`
  font-size: 17px;
  color: ${(props) => props.theme.colors.primary02};
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 16px;
  margin-bottom: 10px;
`;

export default ({ children, dateObj }) => {
  return (
    <Wrapper className={`agenda-day-${dateObj.format("YYYY-MM-DD")}`}>
      <DateLabel>{dateObj && dateObj.format("dddd, Do MMMM YYYY")}</DateLabel>
      {children}
    </Wrapper>
  );
};
