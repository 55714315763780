import React, { useState } from "react";
import styled from "styled-components";

import FeedbackQuestionWrapper from "../components/FeedbackQuestionWrapper";
import SubmitButton from "../components/FeedbackSubmitButton";
import SliderComponent from "../components/Silder";

const GreyText = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 700;
`;

const RegularText = styled.div`
  padding-top: 10px;
`;

const Description = styled.div`
  padding-top: 10px;
`;

const Slider = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.primary02};
  border-radius: ${(props) => props.theme.borders.radius01};
  background: ${(props) => props.theme.colors.secondary05};
  box-sizing: border-box;
  padding: 16px 16px 8px 16px;
  margin-top: 16px;
  font-size: 10px;
`;

const SliderLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export default ({ onSubmit, question, isLastQuestion }) => {
  const { startpoint, endpoint } = question;
  const defaultValue = Math.round((startpoint + endpoint) / 2);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [initialStateChanged, setInitialStateChanged] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ result: selectedValue });
      }}
    >
      <FeedbackQuestionWrapper>
        <GreyText>Bewerten Sie diese Aussage.</GreyText>
        <RegularText>{question.question}</RegularText>

        <Slider>
          <SliderComponent
            value={selectedValue}
            min={startpoint}
            max={endpoint}
            defaultValue={defaultValue}
            onChange={(e) => {
              setInitialStateChanged(true);
              setSelectedValue(e);
            }}
          />
          <SliderLabels>
            <Description>{startpoint} = stimmt nicht</Description>
            <Description>stimmt = {endpoint}</Description>
          </SliderLabels>
        </Slider>
      </FeedbackQuestionWrapper>
      <SubmitButton
        isLastQuestion={isLastQuestion}
        disabled={!initialStateChanged}
      />
    </form>
  );
};
