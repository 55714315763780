import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 20 24"
    >
      <path
        fill="currentColor"
        d="M19.929 20.243l-1.717-2.862A8.5 8.5 0 0117 13.007V10.5a7.009 7.009 0 00-5-6.7V2a2 2 0 00-4 0v1.795a7.009 7.009 0 00-5 6.7v2.507a8.506 8.506 0 01-1.212 4.378L.071 20.242A.5.5 0 00.5 21h19a.5.5 0 00.429-.757z"
      ></path>
      <path
        fill="currentColor"
        d="M0 0a3.323 3.323 0 003 2 3.323 3.323 0 003-2H0z"
        data-name="Path"
        transform="translate(7 22)"
      ></path>
    </svg>
  );
}

export default Icon;
