import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Switch from "react-switch";

export default (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Switch
      {...props}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={theme.colors.accent02}
    />
  );
};
