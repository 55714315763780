import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import client from "../util/apolloClient";
import { gql } from "apollo-boost";

import Modal from "../components/Modal";
import ModalHeading from "../components/ModalHeading";
import FeedbackQuestion from "./FeedbackQuestion";
import ModalProgressBar from "../components/ModalProgressBar";

import feedbackResultQuery from "../util/queries/feedbackResult";

const questionTypes = ["bool", "scale", "text"];

const feedbackQuery = gql`
  query($lecturerId: String!) {
    getTutorFeedback(authorID: $lecturerId) {
      id
      authorID
      kurs
      scale
      bool
      text
    }
  }
`;

const feedbackQuestionQuery = gql`
  query($bool: [String], $scale: [String], $text: [String]) {
    getFeedbackQuestion(scale: $scale, bool: $bool, text: $text) {
      scale {
        order
        question
        startpoint
        endpoint
      }
      bool {
        order
        question
      }
      text {
        order
        question
      }
    }
  }
`;

export default ({ allLecturers, onSubmitFeedback }) => {
  const [feedbackId, setFeedbackId] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questionList, setQuestionList] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [feedbackResults, setFeedbackResults] = useState([]);
  const { lecturerId } = useParams();

  const reorganiseFeedbackQuestions = (feedbackQuestions) => {
    let result = [];
    questionTypes.forEach((questionType) => {
      const allQuestionsOfType = feedbackQuestions[questionType];
      for (const question of allQuestionsOfType) {
        question.type = questionType;
        result.push(question);
      }
    });
    result = result.sort((a, b) => a.order - b.order);
    return result;
  };

  useEffect(() => {
    client
      .query({
        query: feedbackQuery,
        variables: { lecturerId },
      })
      .then((result) => {
        const { bool, scale, text, id } = result.data.getTutorFeedback;
        setFeedbackId(id);
        return client.query({
          query: feedbackQuestionQuery,
          variables: { bool, scale, text },
        });
      })
      .then((result) => {
        const { getFeedbackQuestion } = result.data;
        setQuestionList(reorganiseFeedbackQuestions(getFeedbackQuestion));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
    setLoading(true);
  }, [lecturerId, feedbackId]);

  let lecturerName;

  if (allLecturers) {
    const lecturer = allLecturers.find((el) => el.id === lecturerId);
    if (lecturer) {
      lecturerName = lecturer.vorname + " " + lecturer.nachname;
    }
  }

  if (loading) {
    return (
      <Modal
        modalId={`feedback-${lecturerId}`}
        layer={3}
        closeButtonTo={`/feedback/lecturer/${lecturerId}`}
      ></Modal>
    );
  }

  const currentQuestionObject = questionList[currentQuestion];

  const nextQuestion = (e) => {
    feedbackResults[currentQuestion] = e.result;
    setFeedbackResults(feedbackResults);
    if (currentQuestion + 1 === questionList.length) {
      const answers = feedbackResults.map((result) => result.toString());
      console.log("feedbackId", feedbackId);

      console.log("feedback answers", answers);
      //submit results

      client
        .mutate({
          mutation: feedbackResultQuery,
          variables: { feedbackID: feedbackId, answers },
        })
        .then((result) => {
          onSubmitFeedback();
          console.log(result);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    setCurrentQuestion(currentQuestion + 1);
  };
  const progress = (currentQuestion + 1) / (questionList.length + 1);

  return (
    <Modal
      modalId={`feedback-${lecturerId}`}
      layer={3}
      closeButtonTo={`/feedback/lecturer/${lecturerId}`}
    >
      <ModalProgressBar progress={progress} />
      <ModalHeading title={"Feedback"} subtitle={lecturerName} />
      <FeedbackQuestion
        isLastQuestion={currentQuestion === questionList.length - 1}
        onNextQuestion={nextQuestion}
        question={currentQuestionObject}
        showSuccessMessage={currentQuestion === questionList.length}
      />
    </Modal>
  );
};
