import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";

import { getNextMonths, matchEventsToDays } from "../util/helpers/dateHelpers";
import { defaultPollInterval } from "../util/apolloClient";

import PageWrapper from "../components/PageWrapper";
import CalendarMonth from "../components/CalendarMonth";
import LoadingIndicator from "../components/LoadingIndicator";

const agendaQuery = gql`
  query($date: Int!) {
    getAllLectures(date: $date) {
      lecture {
        id
        studiengang
        startdate
      }
    }
  }
`;

export default ({ onFetchError }) => {
  const { loading, error, data } = useQuery(agendaQuery, {
    variables: { date: moment().startOf("day").unix() },
    pollInterval: defaultPollInterval,
    onError: onFetchError,
  });

  if (!data && loading) {
    return (
      <PageWrapper>
        <LoadingIndicator />
      </PageWrapper>
    );
  }
  if (error) {
    return <PageWrapper>Fehler beim Abrufen der Daten</PageWrapper>;
  }

  const { getAllLectures } = data;
  const lectureDays = matchEventsToDays({ allEvents: getAllLectures });
  const months = getNextMonths({ monthsInAdvace: 12 });

  return (
    <PageWrapper>
      {months.map((month) => (
        <CalendarMonth
          {...month}
          lectureDays={lectureDays}
          key={month.date.toString()}
        ></CalendarMonth>
      ))}
    </PageWrapper>
  );
};
