import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <g fill="currentColor" transform="translate(16 12)">
        <ellipse cx="32" cy="18.063" rx="17" ry="18.063"></ellipse>
        <path d="M15 39h34c8.284 0 15 6.716 15 15v13a2 2 0 01-2 2H2a2 2 0 01-2-2V54c0-8.284 6.716-15 15-15z"></path>
      </g>
    </svg>
  );
}

export default Icon;
