import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";

import { calculateProgress } from "../util/helpers/dateHelpers";
import { defaultPollInterval } from "../util/apolloClient";

import AgendaDetail from "../components/AgendaDetail";

const agendaDetailQuery = gql`
  query($eventId: String!) {
    getAllLectures(id: $eventId) {
      lecture {
        studiengang
        id
        name
        startdate
        enddate
        info
      }
      examen {
        name
        info
        startdate
        enddate
      }
      dozent {
        vorname
        nachname
        email
        tel
      }
    }
  }
`;

export default ({ eventId }) => {
  const { loading, error, data } = useQuery(agendaDetailQuery, {
    variables: { eventId },
    pollInterval: defaultPollInterval,
  });

  let eventData;
  let progress = 0;

  if (data) {
    eventData = data.getAllLectures[0];
    const { startdate, enddate } = eventData.lecture;
    progress = calculateProgress(moment.unix(startdate), moment.unix(enddate));
  }

  console.log(eventData);

  return (
    <AgendaDetail
      eventId={eventId}
      loading={loading}
      eventData={eventData}
      error={error}
      progress={progress}
    />
  );
};
