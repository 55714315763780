import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export default ({ children }) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
};
