import React from "react";
import styled from "styled-components";

const OuterWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  border-radius: ${(props) => props.theme.borders.radius01};
  background: ${(props) => props.theme.colors.secondary04};
  box-sizing: border-box;
  padding: 16px;
  line-height: 1.25; 
`;

export default ({ children }) => {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
};
