import React from "react";
import styled from "styled-components";

const ProgressBar = styled.div`
  background: ${(props) => props.theme.colors.accent};
  height: 5px;
  width: 100%;
  transform: scaleX(${(props) => props.progress});
  transform-origin: left center;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 200ms ease;
`;

export default ({ progress }) => {
  return <ProgressBar progress={progress} />;
};
