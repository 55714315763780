import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g fill="currentColor" transform="translate(-40 -438)">
        <g transform="translate(40 438)">
          <path d="M10.5 0C16.299 0 21 4.701 21 10.5S16.299 21 10.5 21 0 16.299 0 10.5 4.701 0 10.5 0zm.27 7.781H8.48c-.312 0-.312 0-.468.137-.157.137-.157.137-.157.41 0 .274 0 .274.168.418.135.116.135.116.367.139l.122.006h1.523v5.007H7.652c-.328 0-.328 0-.492.133-.164.133-.164.133-.164.406 0 .282 0 .282.156.422.125.113.125.113.35.135l.12.006h6.062c.367 0 .367 0 .504-.121.136-.121.136-.121.136-.441 0-.282 0-.282-.144-.41-.116-.104-.116-.104-.334-.124l-.116-.006h-2.507V8.102c0-.149 0-.149-.121-.235-.091-.064-.091-.064-.233-.08l-.1-.006zm-.188-3.531c-.5 0-.5 0-.652.219-.153.218-.153.218-.153 1.062 0 .469 0 .469.153.617.152.149.152.149.652.149.508 0 .508 0 .652-.16.145-.16.145-.16.145-.832 0-.688 0-.688-.156-.871-.157-.184-.157-.184-.641-.184z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
