import React from "react";
import styled from "styled-components";

const SubmitButton = styled.input`
  color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.accent};
  background-color: ${(props) => props.theme.colors.accent};
  border-radius: 28px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 16px 64px 16px 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease, opacity 200ms ease;
  &:disabled {
    background-color: transparent;
    opacity: 0.6;
  }
`;

export default ({ isLastQuestion, disabled }) => {
  return (
    <SubmitButton
      type="submit"
      value={isLastQuestion ? "abgeben" : "weiter"}
      disabled={disabled}
      isLastQuestion={isLastQuestion}
    />
  );
};
