import React from "react";
import NewsPostLeisure from "../components/NewsPostLeisure";

export default ({ newsArticles }) => {
  if (!newsArticles || newsArticles.length === 0) {
    return <div>keine Artikel vorhanden</div>;
  }
  newsArticles = newsArticles.sort((a, b) => b.date - a.date);
  return (
    <>
      {newsArticles.map((post, index) => {
        return <NewsPostLeisure key={index} {...post} />;
      })}
    </>
  );
};
