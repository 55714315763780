import { transparentize } from "polished";

const darkTheme = {
  name: "Dark Theme",
  isDark: true,
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    primary: "#FFFFFF", // Textfarbe
    primary02: "#777777",
    primary03: "#404040",
    primary04: "#404040", //navigation inactive
    primary05: "#7e7e7e", // subtle icons
    secondary: "#000000", // Hintergrund global
    secondary02: "#0f0f0f", // Navigation
    secondary03: "#0f0f0f", // 1st Card Layer
    secondary04: "#171717", // 2nd Card Layer
    secondary05: "#212121", // 3rd Card Layer
    accent: "#E2001A",
    accent02: "#20AD36",
  },
  borders: {
    radius01: "16px",
    radius02: "16px",
  },
  sizes: { maxLayoutWidth: "450px" },
};

darkTheme.shadows = {
  navigation: `0px 0px 30px 40px ${darkTheme.colors.secondary}`, //1st Card Layer
  card01: "none", // 1st Card Layer (Modal)
  card02: "none", // Second Card Layer
  heading01: `0px 1px 9px 10px ${darkTheme.colors.secondary}`, // Page Heading
};

const lightTheme = {
  name: "Light Theme",
  isDark: false,
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    primary: "#262626", // Textfarbe
    primary02: "#646464",
    primary03: "#6c6c6c",
    primary04: "#9d9d9d", //navigation inactive
    primary05: "#646464", // subtle icons
    secondary: "#ffffff", // Hintergrund global
    secondary02: "#EFEFEF", // Navigation
    secondary03: "#f8f8f8", // 1st Card Layer
    secondary04: "#efefef", // 2nd Card Layer
    secondary05: "#E0E0E0", // 3rd Card Layer
    accent: "#E2001A",
    accent02: "#20AD36",
  },
  borders: darkTheme.borders,
  sizes: darkTheme.sizes,
};

lightTheme.shadows = {
  navigation: `0 3px 14px 0 ${transparentize(
    0.8,
    lightTheme.colors.black
  )}, 0px 0px 30px 40px ${transparentize(0.2, lightTheme.colors.secondary)}`,
  card01: `0 4px 11px 0px ${transparentize(0.85, lightTheme.colors.black)}`, //1st Card Layer
  card02: `0 -4px 5px 0px ${transparentize(0.93, lightTheme.colors.black)}`, // 1st Card Layer (Modal)
  card03: `0 2px 5px 0px ${transparentize(0.93, lightTheme.colors.black)}`, // Second Card Layer
  heading01: `0px 1px 9px 10px ${lightTheme.colors.secondary}`, // Page Heading,
};

export default darkTheme;

export { darkTheme, lightTheme };
