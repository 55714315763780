import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 77 50">
      <g fill="currentColor" transform="translate(-166 -143)">
        <g transform="translate(166 143)">
          <path
            fillRule="nonzero"
            d="M75.495 12.41L39.25.123a2.342 2.342 0 00-1.501 0L1.505 12.409c-.9.306-1.504 1.124-1.505 2.042-.001.918.6 1.737 1.5 2.044l36.244 12.38a2.338 2.338 0 001.512 0L75.5 16.495c.9-.307 1.501-1.126 1.5-2.044-.001-.918-.604-1.736-1.505-2.042z"
          ></path>
          <path
            fillRule="nonzero"
            d="M39 34.54c-.787 0-1.561-.13-2.303-.385L16 27v9.805c0 2.4 2.574 4.428 7.65 6.03C28.074 44.23 33.525 45 39 45s10.926-.769 15.35-2.165c5.076-1.602 7.65-3.63 7.65-6.03V27l-20.697 7.155A7.034 7.034 0 0139 34.54z"
          ></path>
          <path d="M73.481 41.459a4.5 4.5 0 11-4.481.299v-17.15L73.481 23v18.459z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
