import React from "react";
import NewsPostPerson from "../components/NewsPostPerson";

export default ({ newsArticles }) => {
  if (!newsArticles || newsArticles.length === 0) {
    return <div>keine Artikel vorhanden</div>;
  }
  newsArticles = newsArticles.sort((a, b) => b.date - a.date);
  return (
    <>
      {newsArticles.map((post, index) => {
        return <NewsPostPerson key={index} {...post} />;
      })}
    </>
  );
};
