import React from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";

const Wrapper = styled(NavLink)`
  height: 27px;
  width: 27px;
  display: block;
  position: relative;
  display: inline-block;
  padding: 4px;
  margin: -4px;
  -webkit-tap-highlight-color: transparent;
`;

const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const ListIconInner = styled.div`
  background: ${(props) => props.theme.colors.primary};
  height: 11px;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  left: 0;
`;

const ListIconInner1 = styled(ListIconInner)`
  top: 0;
`;

const ListIconInner2 = styled(ListIconInner)`
  bottom: 0;
`;

const GridIconInner = styled.div`
  background: ${(props) => props.theme.colors.primary};
  height: 11px;
  width: 11px;
  border-radius: 2px;
  position: absolute;
`;

const GridIconInner1 = styled(GridIconInner)`
  left: 0;
  top: 0;
`;

const GridIconInner2 = styled(GridIconInner)`
  right: 0;
  top: 0;
`;

const GridIconInner3 = styled(GridIconInner)`
  left: 0;
  bottom: 0;
`;

const GridIconInner4 = styled(GridIconInner)`
  right: 0;
  bottom: 0;
`;

export default ({ toGrid, toList }) => {
  const location = useLocation();

  if (location.pathname === toGrid) {
    return (
      <Wrapper to={toList}>
        <InnerWrapper>
          <ListIconInner1 />
          <ListIconInner2 />
        </InnerWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper to={toGrid}>
      <InnerWrapper>
        <GridIconInner1 />
        <GridIconInner2 />
        <GridIconInner3 />
        <GridIconInner4 />
      </InnerWrapper>
    </Wrapper>
  );
};
