import React, { useState } from "react";
import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  transition: opacity 400ms ease;
  opacity: ${(props) => (props.visible ? "1" : "0")};
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 400ms ease;
  background-color: ${(props) => props.theme.colors.secondary03};
  opacity: ${(props) => (props.visible ? "1" : "0")};
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export default (props) => {
  const { source } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Wrapper {...props}>
      <Placeholder visible={!isLoaded} />
      <Image
        src={source}
        visible={isLoaded}
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
      />
    </Wrapper>
  );
};
