import React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  min-height: 100px;
  background: ${(props) => props.theme.colors.secondary04};
  border-radius: ${(props) => props.theme.borders.radius01};
  box-shadow: ${(props) => props.theme.shadows.card01};
  padding: 12px 12px 18px;
  margin-bottom: 18px;
  box-sizing: border-box;
  font-size: 17px;
`;

const Heading = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  text-transform: uppercase;
  font-weight: bold;
  grid-column-start: 1;
  grid-column-end: 7;
  padding-left: 10px;
`;

const GridWrapper = styled.div`
  width: 100%;
  padding: 0 8px 8px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 44px;
  column-gap: 4px;
  row-gap: 4px;
`;

const HeaderWrapper = styled(GridWrapper)`
  grid-auto-rows: auto;
`;

const GridHeader = styled(GridWrapper)`
  padding-bottom: 0;
`;

const GridBody = styled(GridWrapper)`
  border-top: 1px solid ${(props) => props.theme.colors.primary04};
`;

const dayStyles = css`
  place-self: center;
  font-weight: bold;
  background: ${(props) =>
    props.isToday ? props.theme.colors.accent : "none"};
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const DayPlaceholder = styled.div`
  ${dayStyles}
  color: ${(props) => props.theme.colors.primary02};
`;

const activeDayStyles = css`
  ${dayStyles}
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  position: relative;
  color: ${(props) => props.theme.colors.primary};
`;

const InactiveDay = styled.div`
  ${activeDayStyles}
`;

const Day = styled(Link)`
  ${activeDayStyles}
  &:active {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary04};
  }
  &::after {
    content: "";
    display: block;
    height: 6px;
    width: 6px;
    position: absolute;
    background: ${(props) => props.theme.colors.primary02};
    bottom: -9px;
    border-radius: 50%;
    pointer-events: none;
  }
`;

export default ({ date, days, lectureDays }) => {
  const monthNumber = date.get("month");
  const yearNumber = date.get("year");
  const placeholderDays = new Array(
    date.clone().startOf("month").isoWeekday() - 1
  ).fill(null);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Heading>{date.format("MMMM YYYY")}</Heading>
      </HeaderWrapper>
      <GridHeader>
        <DayPlaceholder>M</DayPlaceholder>
        <DayPlaceholder>D</DayPlaceholder>
        <DayPlaceholder>M</DayPlaceholder>
        <DayPlaceholder>D</DayPlaceholder>
        <DayPlaceholder>F</DayPlaceholder>
        <DayPlaceholder>S</DayPlaceholder>
        <DayPlaceholder>S</DayPlaceholder>
      </GridHeader>
      <GridBody>
        {placeholderDays.map((item, index) => (
          <DayPlaceholder
            key={`placeholder${index}-${monthNumber}-${yearNumber}`}
          />
        ))}
        {days.map((day) => {
          const { date } = day;

          const isBeforeToday = moment().startOf("day").diff(date) > 0;
          if (isBeforeToday) {
            return (
              <DayPlaceholder
                key={`${date.date()}-${monthNumber}-${yearNumber}`}
              >
                {date.format("D")}
              </DayPlaceholder>
            );
          }
          const isToday = date.isSame(moment(), "day");
          const hasEvent =
            lectureDays.findIndex((el) => date.isSame(el.date, "day")) >= 0;

          if (!hasEvent) {
            return (
              <InactiveDay
                key={`${date.date()}-${monthNumber}-${yearNumber}`}
                isToday={isToday}
              >
                {day.date.format("D")}
              </InactiveDay>
            );
          }
          return (
            <Day
              to={{
                pathname: "/agenda/list",
                search: `?scrollTo=${date.format("YYYY-MM-DD")}`,
              }}
              key={`${date.date()}-${monthNumber}-${yearNumber}`}
              isToday={isToday}
              hasEvent={hasEvent}
            >
              {day.date.format("D")}
            </Day>
          );
        })}
      </GridBody>
    </Wrapper>
  );
};
