import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" width="100%">
      <path
        fill="currentColor"
        d="M12.484 1.394a.816.816 0 00.012-1.15.816.816 0 00-1.15-.013L6.365 5.113 1.383.233a.812.812 0 00-1.15.013.812.812 0 00.012 1.15l4.96 4.855-4.96 4.857a.816.816 0 00-.012 1.15.816.816 0 001.15.013l4.982-4.883 4.981 4.88c.32.315.836.31 1.15-.012a.812.812 0 00-.012-1.15L7.524 6.25l4.96-4.857z"
      ></path>
    </svg>
  );
}

export default Icon;
