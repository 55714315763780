import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="path-1"
          d="M10 6L8.59 7.41 13.17 12 8.59 16.59 10 18 16 12z"
        ></path>
      </defs>
      <use fill="currentColor" xlinkHref="#path-1"></use>
    </svg>
  );
}

export default Icon;
