import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 0 3rem 1rem;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  font-size: 2rem;
  margin: 0;
  padding-left: 16px; 
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 600;
  padding-left: 16px; 
`;

export default ({ title, subtitle }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  );
};
