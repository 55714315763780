import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 217 109"
      version="1.1"
      viewBox="0 0 217 109"
      xmlSpace="preserve"
      height="100%"
    >
      <g transform="translate(-99 -143)" fill="currentColor">
        <g transform="translate(166 143)">
          <path
            d="M76.2 18.1L40 5.8c-.5-.2-1-.2-1.5 0L2.2 18.1c-1.1.3-1.8 1.5-1.4 2.7.2.7.7 1.2 1.4 1.4l36.3 12.4c.5.2 1 .2 1.5 0l36.2-12.4c1.1-.4 1.8-1.6 1.4-2.7-.2-.6-.7-1.2-1.4-1.4z"
            className="st0"
          ></path>
          <path
            d="M39.7 40.3c-.8 0-1.6-.1-2.3-.4l-20.7-7.2v9.8c0 2.4 2.6 4.4 7.6 6 5 1.5 10.2 2.2 15.4 2.2S50.1 50 55 48.5c5.1-1.6 7.6-3.6 7.6-6v-9.8L42 39.9c-.7.2-1.5.4-2.3.4z"
            className="st0"
          ></path>
          <path
            d="M67.7 51.2c0-1.5.8-2.9 2-3.7V30.4l4.5-1.6v18.5c2.2 1.1 3.2 3.8 2.1 6s-3.8 3.2-6 2.1c-1.6-.9-2.6-2.5-2.6-4.2z"
            className="st0"
          ></path>
        </g>
        <g className="st1">
          <path
            d="M137.4 235.9c.5 2.9 3.4 4.6 7.3 4.6s6.5-1.9 6.5-4.4c0-2.3-1.6-3.6-6-4.4l-3.6-.7c-6.9-1.4-10.3-4.7-10.3-9.8 0-6.3 5.5-10.5 13.2-10.5 8.1 0 13.1 4.2 13.3 10.6H151c-.2-2.9-2.8-4.7-6.4-4.7-3.6 0-6 1.7-6 4.2 0 2.1 1.7 3.4 5.7 4.3l3.7.7c7.3 1.5 10.5 4.4 10.5 9.6 0 6.7-5.3 11-13.9 11-8.2 0-13.7-3.9-13.9-10.4h6.7zM175.1 245.8v-28.6h-10v-6h27.2v6h-10v28.6h-7.2zM207.5 232.8c0 4.4 2.6 7.3 7.2 7.3s7.2-2.9 7.2-7.3v-21.6h7.2v22.3c0 7.6-5.6 12.8-14.5 12.8s-14.5-5.2-14.5-12.8v-22.3h7.2v21.6zM252.1 211.2c10.4 0 16.5 6.2 16.5 17.1 0 11-6.1 17.4-16.5 17.4h-13.2v-34.5h13.2zm-6 28.6h5.1c6.5 0 10-3.9 10-11.4 0-7.2-3.6-11.2-10-11.2h-5.1v22.6zM277.2 245.8v-34.5h7.2v34.5h-7.2z"
            className="st0"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
