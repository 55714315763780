import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import Logo from "../components/icons/Logo";

const pulseAnimation = keyframes`
  0% {
		transform: scale(0.85);
	}

  50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.85);
	}
`;

const Wrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 80px;
  animation: ${pulseAnimation} 500ms ease-out infinite;
  color: ${(props) => props.theme.colors.primary03};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  ${(props) => props.delayTransition && "transition: opacity 300ms ease;"}
`;

export default ({ delay = 500, delayTransition = true }) => {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImage(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <Wrapper>
      <ImageWrapper visible={showImage} delayTransition={delayTransition}>
        <Logo />
      </ImageWrapper>
    </Wrapper>
  );
};
