import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/react-hooks";
import moment from "moment";
import "moment/locale/de";
import { AnimatePresence } from "framer-motion";

import { darkTheme, lightTheme } from "./util/theme";
import GlobalStyle from "./util/GlobalStyle";
import cordovaInit from "./util/cordova-init";
import registerPush from "./util/register-push";
import client from "./util/apolloClient";
import ScrollToTop from "./util/ScrollToTop";

import NewsPage from "./pages/News";
import AgendaPage from "./pages/Agenda";
import FeedbackPage from "./pages/Feedback";
import ProfilePage from "./pages/Profile";
import AuthPage from "./pages/Authentication";
import ImprintPage from "./pages/Imprint";

import BottomNavigation from "./components/BottomNavigation";
import GlobalWrapper from "./components/GlobalWrapper";

moment.locale("de");

function App() {
  const [initiallyLoading, setInitiallyLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(
    process.env.REACT_APP_BYPASS_AUTH === "TRUE"
  );
  const [loginData, setLoginData] = useState(null);
  const [lightThemeActive, setLightThemeActive] = useState(false);

  const onLogin = ({ userId, token, tokenExpiration }) => {
    setIsLoggedIn(true);
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiration", tokenExpiration);
  };

  const onLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
  };

  const onFetchError = () => {
    onLogout();
  };

  const readLoginData = () => {
    const token = localStorage.getItem("token");
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    if (token && tokenExpiration) {
      if (moment.unix(tokenExpiration).isBefore(moment())) {
        setIsLoggedIn(true);
        setLoginData({ token, tokenExpiration });
      }
    }
    setInitiallyLoading(false);
  };

  useEffect(() => {
    readLoginData();
    cordovaInit();
    registerPush();
  }, []);

  const RouteLoggedIn = (
    <Switch>
      <Route exact path="/agenda">
        <Redirect to="/agenda/list" />
      </Route>
      <Route exact path="/news">
        <Redirect to="/news/dhbw" />
      </Route>
      <Route path="/imprint">
        <ImprintPage />
      </Route>
      <Route path="/news/:newsTab">
        <NewsPage onFetchError={onFetchError} />
      </Route>
      <Route path="/feedback">
        <FeedbackPage onFetchError={onFetchError} />
      </Route>
      <Route path="/me/:modalType?">
        <ProfilePage
          loginData={loginData}
          onLogout={onLogout}
          onFetchError={onFetchError}
          onThemeChange={() => {
            setLightThemeActive(!lightThemeActive);
          }}
        />
      </Route>
      <Route path="/agenda/:gridOrList">
        <AgendaPage onFetchError={onFetchError} />
      </Route>
      <Route>
        <Redirect to="/agenda/list" />
      </Route>
    </Switch>
  );

  const RouteNotLoggedIn = (
    <Switch>
      <Route path="/auth/:loginVariant">
        <AuthPage onLogin={onLogin} />
      </Route>
      <Route path="/imprint">
        <ImprintPage />
      </Route>
      <Route>
        <Redirect to="/auth/login" />
      </Route>
    </Switch>
  );

  const Routes = isLoggedIn ? RouteLoggedIn : RouteNotLoggedIn;
  if (initiallyLoading) return null;
  return (
    <ThemeProvider theme={lightThemeActive ? lightTheme : darkTheme}>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <GlobalWrapper>
          <Router>
            <AnimatePresence initial={false}>
              <ScrollToTop>
                {Routes}
                {isLoggedIn && <BottomNavigation />}
              </ScrollToTop>
            </AnimatePresence>
          </Router>
        </GlobalWrapper>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
