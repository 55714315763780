import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import ArrowIcon from "../components/icons/ArrowRight";

const Wrapper = styled(Link)`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  height: 76px;
  margin-left: 30px;
  width: calc(100% - 30px);
  background: ${(props) => props.theme.colors.secondary03};
  border-radius: ${(props) => props.theme.borders.radius01};
  position: relative;
  padding: 16px;
  padding-left: 72px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  line-height: 1;
  box-shadow: ${(props) => props.theme.shadows.card01};
  &:not(last-child) {
    margin-bottom: 22px;
  }
`;

const AvatarWrapper = styled.div`
  height: 76px;
  width: 76px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.colors.primary};
  position: absolute;
  left: -30px;
  top: -2px;
  background: ${(props) => props.theme.colors.secondary03};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NotificationBadge = styled.div`
  background: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  right: -1px;
  top: -9px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
`;

const Title = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;

const Subtitle = styled.span`
  font-size: 10px;
  color: ${(props) => props.theme.colors.primary02};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
`;

export default ({ badgeCount, avatarImage, name, moduleName, to }) => {
  return (
    <Wrapper to={to}>
      <AvatarWrapper>
        <AvatarImage src={avatarImage} />
      </AvatarWrapper>
      {!!badgeCount && badgeCount > 0 && (
        <NotificationBadge>{badgeCount}</NotificationBadge>
      )}
      <span>
        <Title>{name}</Title>
        <br />
        <Subtitle>{moduleName}</Subtitle>
      </span>
      <IconWrapper>
        <ArrowIcon />
      </IconWrapper>
    </Wrapper>
  );
};
