import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import ToggleSwitch from "../components/ToggleSwitch";

import Modal from "../components/Modal";
import PageWrapper from "../components/PageWrapper";
import ModalHeading from "../components/ModalHeading";
import InfoIcon from "../components/icons/Info";
import ArrowRightIcon from "../components/icons/ArrowRightSmall";
import SunIcon from "../components/icons/Sun";
import KeyIcon from "../components/icons/Key";
import BellIcon from "../components/icons/Bell";

const ProfileImage = styled.img`
  border-radius: 100%;
  object-fit: cover;
  width: 60px;
  height: 60px;
`;

const IconWrapper = styled.div`
  position: relative;
  top: 4px;
`;

const ArrowButton = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  display: block;
  align-self: center;
  align-items: right;
`;

const SettingsWrapper = styled.div`
  padding: 0 0 20px 16px;
  display: flex;
  justify-content: space-between;
`;

const Setting = styled.div`
  left: 70px;
  position: absolute;
  align-self: center;
`;

const SettingProfileImage = styled.div`
  left: 110px;
  position: absolute;
  align-self: center;
`;

const SubmitButton = styled.input`
  color: ${(props) => props.theme.colors.primary};
  background: none;
  border: none;
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
`;

export default ({ onLogout, onThemeChange, user }) => {
  let picUrl;

  if (!!user && !!user.picurl) {
    picUrl = user.picurl;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    onLogout && onLogout();
  };
  const theme = useContext(ThemeContext);
  const [notificationsActive, setNotificationsActive] = useState(true);
  return (
    <Modal modalId="settings" closeButtonTo={"/me"}>
      <PageWrapper>
        <ModalHeading title="Einstellungen" />
        <SettingsWrapper>
          {!!picUrl && <ProfileImage src={picUrl} />}
          <SettingProfileImage>Profilbild ändern</SettingProfileImage>
          <ArrowButton to="">
            <ArrowRightIcon />
          </ArrowButton>
        </SettingsWrapper>
        <SettingsWrapper>
          <IconWrapper>
            <KeyIcon />
          </IconWrapper>
          <Setting>Passwort ändern</Setting>
          <ArrowButton to="">
            <ArrowRightIcon />
          </ArrowButton>
        </SettingsWrapper>
        <SettingsWrapper>
          <IconWrapper>
            <BellIcon />
          </IconWrapper>
          <Setting>Benachrichtigungen</Setting>
          <ToggleSwitch
            checked={notificationsActive}
            onChange={() => {
              setNotificationsActive(!notificationsActive);
            }}
          />
        </SettingsWrapper>
        <SettingsWrapper>
          <IconWrapper>
            <SunIcon />
          </IconWrapper>
          <Setting>Dark Mode</Setting>
          <ToggleSwitch onChange={onThemeChange} checked={theme.isDark} />
        </SettingsWrapper>
        <SettingsWrapper>
          <IconWrapper>
            <InfoIcon />
          </IconWrapper>
          <Setting>Impressum</Setting>
          <ArrowButton to="/me/settings/imprint">
            <ArrowRightIcon />
          </ArrowButton>
        </SettingsWrapper>
        <SettingsWrapper>
          <form onSubmit={onSubmit}>
            <SubmitButton type="submit" value="abmelden" />
          </form>
        </SettingsWrapper>
      </PageWrapper>
    </Modal>
  );
};
