import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
    >
      <path
        fill="currentColor"
        d="M8 22a3 3 0 01-3-3V8a2 2 0 012-2h7a3 3 0 013 3v10a3 3 0 01-3 3zm-4-5H3a3 3 0 01-3-3V3a3 3 0 013-3h6a3 3 0 013 3v2H7a3 3 0 00-3 3v9z"
        data-name="Combined Shape"
        opacity="0.4"
      ></path>
    </svg>
  );
}

export default Icon;
