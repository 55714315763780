import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.secondary04};
  border-radius: ${(props) => props.theme.borders.radius01};
  padding: 16px;
  padding-bottom: 10px;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const Title = styled.span`
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
`;

const SmallText = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.primary02};
`;

const Room = styled(SmallText)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Lecturer = styled(SmallText)`
  margin-bottom: 8px;
`;

const ProgressBar = styled.div`
  background: ${(props) => props.theme.colors.accent};
  height: 5px;
  width: 100%;
  transform: scaleX(${(props) => props.progress});
  transform-origin: left center;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export default ({
  progress,
  title,
  room,
  lecturer,
  startDate,
  endDate,
  eventId,
}) => {
  const startTime = startDate.format("HH:mm");
  const endTime = endDate.format("HH:mm");

  return (
    <Wrapper to={`?event=${eventId}`}>
      <Title>{title}</Title>
      <Room>{room}</Room>
      {lecturer && <Lecturer>{lecturer}</Lecturer>}
      <SmallText>
        {startTime} - {endTime}
      </SmallText>
      {progress > 0 && <ProgressBar progress={progress} />}
    </Wrapper>
  );
};
