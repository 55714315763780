import React from "react";
import styled from "styled-components";
import LoginPage from "../pages/Login";
import RegisterPage from "../pages/Register";
import ForgotPassword from "./ForgotPassword";
import { useRouteMatch, Link } from "react-router-dom";

import NavTabs from "../components/NavTabs";
import LogoLarge from "../components/LogoLarge";

const pages = [
  { to: "/auth/login", text: "Anmelden" },
  { to: "/auth/register", text: "Registrieren" },
];

const forgotPasswordPages = [
  { to: "/auth/forgot-password", text: "Passwort vergessen" },
];

const Wrapper = styled.div``;

const ImprintLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary02};
  padding: 16px;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
`;

export default ({ onLogin }) => {
  let match = useRouteMatch("/auth/:loginVariant");

  const { loginVariant } = match.params;

  const tabPages =
    loginVariant === "forgot-password" ? forgotPasswordPages : pages;

  return (
    <Wrapper>
      <LogoLarge />
      <NavTabs pages={tabPages} />
      {loginVariant === "login" && <LoginPage onLogin={onLogin} />}
      {loginVariant === "register" && <RegisterPage />}
      {loginVariant === "forgot-password" && <ForgotPassword />}
      <ImprintLink to="/imprint">Impressum</ImprintLink>
    </Wrapper>
  );
};
