import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="8"
        fill="currentColor"
        transform="translate(5 5)"
      ></circle>
      <path
        fill="currentColor"
        d="M.5 3a.5.5 0 00.5-.5v-2a.5.5 0 10-1 0v2a.5.5 0 00.5.5z"
        transform="translate(12)"
      ></path>
      <path
        fill="currentColor"
        d="M.5 0a.5.5 0 00-.5.5v2a.5.5 0 101 0v-2A.5.5 0 00.5 0z"
        data-name="Path"
        transform="translate(12 22)"
      ></path>
      <path
        fill="currentColor"
        d="M2.5 0h-2a.5.5 0 100 1h2a.5.5 0 100-1z"
        data-name="Path"
        transform="translate(22 12)"
      ></path>
      <path
        fill="currentColor"
        d="M3 .5a.5.5 0 00-.5-.5h-2a.5.5 0 100 1h2A.5.5 0 003 .5z"
        data-name="Path"
        transform="translate(0 12)"
      ></path>
      <path
        fill="currentColor"
        d="M1.065 2.725A.494.494 0 001.5 3a.46.46 0 00.25-.074.58.58 0 00.183-.751l-1-1.9a.476.476 0 00-.685-.2.58.58 0 00-.183.746z"
        data-name="Path"
        transform="translate(6 2)"
      ></path>
      <path
        fill="currentColor"
        d="M.932.272a.476.476 0 00-.684-.2.581.581 0 00-.183.749l1 1.9A.494.494 0 001.5 3a.459.459 0 00.25-.074.58.58 0 00.183-.751z"
        data-name="Path"
        transform="translate(17 20)"
      ></path>
      <path
        fill="currentColor"
        d="M.545 2a.578.578 0 00.273-.067L2.707.938a.477.477 0 00.23-.671.572.572 0 00-.737-.21l-.04.021-1.887.994a.474.474 0 00-.2.679A.557.557 0 00.545 2z"
        data-name="Path"
        transform="translate(20 6)"
      ></path>
      <path
        fill="currentColor"
        d="M2.162.077l-1.889.995a.474.474 0 00-.2.68A.557.557 0 00.545 2a.578.578 0 00.273-.067L2.707.938a.477.477 0 00.23-.671.572.572 0 00-.737-.21l-.04.021z"
        data-name="Path"
        transform="translate(2 17)"
      ></path>
      <path
        fill="currentColor"
        d="M.294.939l1.889.994A.578.578 0 002.455 2 .523.523 0 003 1.5a.491.491 0 00-.273-.431L.838.078a.578.578 0 00-.753.153.472.472 0 00.168.686l.04.021z"
        data-name="Path"
        transform="translate(2 6)"
      ></path>
      <path
        fill="currentColor"
        d="M2.727 1.073L.838.078a.577.577 0 00-.753.153.472.472 0 00.168.686l.04.021 1.889.995A.578.578 0 002.455 2 .523.523 0 003 1.5a.491.491 0 00-.273-.431z"
        data-name="Path"
        transform="translate(20 17)"
      ></path>
      <path
        fill="currentColor"
        d="M.249 2.926A.46.46 0 00.5 3a.494.494 0 00.434-.275l1-1.9a.58.58 0 00-.187-.753.477.477 0 00-.679.2l-1 1.9a.58.58 0 00.181.754z"
        data-name="Path"
        transform="translate(17 2)"
      ></path>
      <path
        fill="currentColor"
        d="M1.749.074a.476.476 0 00-.683.2l-1 1.9a.579.579 0 00.183.75A.459.459 0 00.5 3a.493.493 0 00.434-.275l1-1.9A.579.579 0 001.75.074z"
        data-name="Path"
        transform="translate(6 20)"
      ></path>
    </svg>
  );
}

export default Icon;
