import React from "react";
import FeedbackQuestionYesNo from "../components/FeedbackQuestionYesNo";
import FeedbackQuestionText from "../components/FeedbackQuestionText";
import FeedbackQuestionSlider from "../components/FeedbackQuestionSlider";
import FeedbackSuccess from "../components/FeedbackSuccess";

export default ({
  question,
  onNextQuestion,
  isLastQuestion,
  showSuccessMessage,
}) => {
  if (showSuccessMessage) {
    return <FeedbackSuccess />;
  }

  switch (question.type) {
    case "bool":
      return (
        <FeedbackQuestionYesNo
          question={question}
          isLastQuestion={isLastQuestion}
          onSubmit={onNextQuestion}
        />
      );
    case "scale":
      return (
        <FeedbackQuestionSlider
          isLastQuestion={isLastQuestion}
          question={question}
          onSubmit={onNextQuestion}
        />
      );
    case "text":
      return (
        <FeedbackQuestionText
          isLastQuestion={isLastQuestion}
          question={question}
          onSubmit={onNextQuestion}
        />
      );
    default:
      break;
  }
};
