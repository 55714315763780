import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <g fill="currentColor" transform="translate(14 11)">
        <rect width="18" height="74" x="25" y="0" rx="2"></rect>
        <rect width="18" height="32" x="0" y="42" rx="2"></rect>
        <rect width="18" height="50" x="50" y="24" rx="2"></rect>
      </g>
    </svg>
  );
}

export default Icon;
