import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="13"
      viewBox="0 0 24 13"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          transform="translate(-39 -298)"
        >
          <g transform="translate(39 298)">
            <path d="M12.71 4.333A6.538 6.538 0 006.544 0C2.93 0 0 2.909 0 6.5S2.93 13 6.545 13a6.538 6.538 0 006.164-4.333h4.746V13h4.363V8.667H24V4.333H12.71zM6.5 9a2.5 2.5 0 11-.001-4.999A2.5 2.5 0 016.5 9z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
