import React from "react";
import styled from "styled-components";
import moment from "moment";

import Modal from "../components/Modal";
import PageWrapper from "../components/PageWrapper";
import LoadingIndicator from "../components/LoadingIndicator";

const AgendaTitle = styled.div`
  margin: 0 0 3rem 1rem;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  font-size: 2rem;
  margin: 0;
`;

const Date = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  text-transform: uppercase;
  font-weight: 600;
`;

const DescriptionWrapper = styled.div`
  background: ${(props) => props.theme.colors.secondary04};
  padding: 1rem;
  border-radius: ${(props) => props.theme.borders.radius01};
  margin-bottom: 28px;
  box-shadow: ${(props) => props.theme.shadows.card03};
`;

const LecturerName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
`;

const LecturerEmail = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const LecturerDescription = styled.div`
  color: ${(props) => props.theme.colors.primary};
`;

const ExamsWrapper = styled.div`
  background: ${(props) => props.theme.colors.secondary04};
  padding: 1rem;
  border-radius: ${(props) => props.theme.borders.radius01};
  box-shadow: ${(props) => props.theme.shadows.card03};
`;

const ExamsHeader = styled.div`
  padding: 0 1rem 1rem 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary02};
`;

const Exam = styled.div`
  background: ${(props) => props.theme.colors.secondary05};
  text-transform: uppercase;
  padding: 1rem;
  border-radius: ${(props) => props.theme.borders.radius01};
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ExamTitle = styled.div`
  font-weight: 600;
  padding-bottom: 0.5rem;
`;

const ExamDate = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.primary02};
`;

const ProgressBar = styled.div`
  background: ${(props) => props.theme.colors.accent};
  height: 5px;
  width: 100%;
  transform: scaleX(${(props) => props.progress});
  transform-origin: left center;
  position: absolute;
  left: 0;
  top: 0;
`;

export default ({ eventId, eventData, loading, progress }) => {
  if (loading)
    return (
      <Modal modalId={eventId} closeButtonTo="">
        <LoadingIndicator />
      </Modal>
    );
  else if (!eventData) {
    return (
      <Modal modalId={eventId} closeButtonTo="">
        Fehler: Ereignis nicht gefunden
      </Modal>
    );
  }
  const { lecture, examen, dozent } = eventData;
  const { startdate, info, name } = lecture;
  const { email, vorname, nachname } = dozent;
  const dozentname = vorname + " " + nachname;

  const dateString = moment.unix(startdate).format("dddd, Do MMMM YYYY");

  return (
    <Modal modalId={eventId} closeButtonTo={{ search: "" }}>
      {progress && <ProgressBar progress={progress} />}
      <PageWrapper noPaddingBottom>
        <AgendaTitle>
          <Title>{name}</Title>
          <Date>{dateString}</Date>
        </AgendaTitle>
        <DescriptionWrapper>
          <LecturerName>{dozentname}</LecturerName>
          <LecturerEmail>{email}</LecturerEmail>
          {(info || info.length === 0) && (
            <LecturerDescription>{info}</LecturerDescription>
          )}
        </DescriptionWrapper>
        {!!examen && (
          <ExamsWrapper>
            <ExamsHeader>Prüfungen</ExamsHeader>
            <Exam>
              <ExamTitle>{examen.name}</ExamTitle>
              <ExamDate>
                {moment.unix(examen.startdate).format("DD.MM.YYYY")}
              </ExamDate>
            </Exam>
          </ExamsWrapper>
        )}
      </PageWrapper>
    </Modal>
  );
};
