import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

import FeedbackQuestionWrapper from "../components/FeedbackQuestionWrapper";

const SuccessMessage = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 700;
  padding-bottom: 10px; 
`;

export default () => {
  const { lecturerId } = useParams();

  return (
    <FeedbackQuestionWrapper>
      <SuccessMessage>
        Feedback erfolgreich abgegeben!
      </SuccessMessage>
      Vielen Dank, dass du an diesem Feedback teilgenommen hast.
      {/* <Link to={`/feedback/lecturer/${lecturerId}`}>ok</Link> */}
    </FeedbackQuestionWrapper>
  );
};
