import React, { useState } from "react";

import RegisterForm from "../components/RegisterForm";
import client from "../util/apolloClient";
import { gql } from "apollo-boost";
import LoadingIndicator from "../components/LoadingIndicator";
// import loginQuery from "./../queries/loginQuery";

const registerQuery = gql`
  query($username: Int!, $password: String!) {
    register(matrikelnum: $username, password: $password) {
      userId
      token
      tokenExpiration
    }
  }
`;

export default ({ onRegister }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (event) => {
    const { username, password, e } = event;
    e.preventDefault();

    client
      .query({
        query: registerQuery,
        variables: { username: parseInt(username), password },
      })
      .then((result) => {
        onRegister(result.data.login);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessage('Deine Matrikelnummer ist bereits registriert. Wechsle zum Tab "Anmelden" um mit der Anmeldung fortzufahren.');
        console.error(e);
      });
    setLoading(true);
  };
  return (
    <>
      {!loading && (
        <RegisterForm errorMessage={errorMessage} onSubmit={onSubmit} />
      )}
      {loading && <LoadingIndicator delay={0} />}
    </>
  );
};
