import { gql } from "apollo-boost";

export default gql`
  query {
    getMyProfile {
      user {
        id
        vorname
        nachname
        email
        tel
        web
        picurl
        matrikelnum
        studiengang
      }
      dualis {
        ects
        semesterscore
        modules
        modulenotes
        moduleects
      }
    }
  }
`;
