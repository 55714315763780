import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  position: relative;
  ${(props) => !props.noPaddingBottom && "padding-bottom: 200px;"}
`;

export default ({ children, noPaddingBottom }) => {
  return (
    <>
      <Wrapper className="page-wrapper" noPaddingBottom={noPaddingBottom}>
        {children}
      </Wrapper>
    </>
  );
};
