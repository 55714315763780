import React from "react";
import PageHeading from "../components/PageHeading";
import NavTabs from "../components/NavTabs";
import { Route, useRouteMatch } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import PageWrapper from "../components/PageWrapper";
import NewsDhbw from "../components/NewsDhbw";
import NewsFood from "../components/NewsFood";
import NewsLeisure from "../components/NewsLeisure";
import { defaultPollInterval } from "../util/apolloClient";
import newsQuery from "../util/queries/news";
import LoadingIndicator from "../components/LoadingIndicator";

const pages = [
  { to: "/news/dhbw", text: "DHBW" },
  { to: "/news/food", text: "Mensa" },
  { to: "/news/leisure", text: "Freizeit" },
];

const newsTypes = { dhbw: "DHBW", food: "MENSA", leisure: "FREETIME" };

export default ({ onFetchError }) => {
  let match = useRouteMatch("/news/:newsTab");
  const { newsTab } = match.params;

  const newstype = newsTypes[newsTab];

  const { loading, error, data } = useQuery(newsQuery, {
    pollInterval: defaultPollInterval,
    variables: { newstype },
    onError: onFetchError,
  });

  let newsArticles;

  if (data) {
    newsArticles = data.getAllNews;
  }

  const routes = (
    <>
      <Route exact path="/news/dhbw">
        <NewsDhbw newsArticles={newsArticles} />
      </Route>
      <Route exact path="/news/food">
        <NewsFood newsArticles={newsArticles} />
      </Route>
      <Route exact path="/news/leisure">
        <NewsLeisure newsArticles={newsArticles} />
      </Route>
    </>
  );

  return (
    <>
      <PageHeading>News</PageHeading>
      <PageWrapper>
        <NavTabs pages={pages} />
        {!loading && routes}
        {loading && <LoadingIndicator />}
      </PageWrapper>
    </>
  );
};
