import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 96px;
  margin-bottom: 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: ${(props) => props.theme.shadows.heading01};
`;

const Text = styled.h2`
  font-size: 34px;
  margin: 0;
`;

export default (props) => {
  const { children, Button } = props;
  return (
    <Wrapper>
      <Text>{children}</Text>
      {Button && <Button {...props} />}
    </Wrapper>
  );
};
