import React, { useState } from "react";
import styled from "styled-components";

import FeedbackQuestionWrapper from "../components/FeedbackQuestionWrapper";
import SubmitButton from "../components/FeedbackSubmitButton";

const GreyText = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 700;
`;

const RegularText = styled.div`
  padding-top: 10px;
`;

const Textarea = styled.textarea`
  color: ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.borders.radius01};
  background: ${(props) => props.theme.colors.secondary05};
  box-sizing: border-box;
  padding: 16px;
  border: none;
  width: 100%;
  margin-top: 16px;
  font-size: 16px;
  outline: none;
  resize: none;
  overflow: hidden;
  height: 50px;
  font-family: "Roboto", sans-serif;
  ::placeholder {
    color: ${(props) => props.theme.colors.primary02};
  }
`;

export default ({ onSubmit, question, isLastQuestion }) => {
  const [currentResult, setCurrentResult] = useState("");

  const onChange = (e) => {
    setCurrentResult(e.target.value);
    e.target.style.height = "1px";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ result: currentResult });
      }}
    >
      <FeedbackQuestionWrapper>
        <GreyText>Bewerten Sie folgende Aussage.</GreyText>
        <RegularText>{question.question}</RegularText>
        <Textarea
          value={currentResult}
          onChange={onChange}
          placeholder="Schreib hier den Text"
        ></Textarea>
      </FeedbackQuestionWrapper>
      <SubmitButton
        isLastQuestion={isLastQuestion}
        disabled={currentResult.length === 0}
      />
    </form>
  );
};
