import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <g fill="currentColor" fillRule="nonzero" transform="translate(7 17)">
        <path d="M80.397 13.693L41.799.136a2.411 2.411 0 00-1.598 0L1.603 13.693A2.392 2.392 0 000 15.946a2.392 2.392 0 001.598 2.256l38.597 13.66a2.41 2.41 0 001.61 0l38.597-13.66A2.392 2.392 0 0082 15.946a2.393 2.393 0 00-1.603-2.253zM76.74 50.181V29l-4.48 1.727V50.18c-1.35.842-2.26 2.42-2.26 4.229 0 1.809.91 3.386 2.26 4.228v11.934c0 1.34 1.003 2.428 2.24 2.428 1.237 0 2.24-1.087 2.24-2.428V58.638c1.35-.841 2.26-2.42 2.26-4.228 0-1.81-.91-3.387-2.26-4.229z"></path>
        <path d="M42.5 39.96a6.978 6.978 0 01-2.353-.408L19 32v10.35c0 2.532 2.63 4.673 7.816 6.364C31.336 50.188 36.906 51 42.5 51c5.594 0 11.164-.812 15.684-2.286C63.37 47.024 66 44.882 66 42.35V32l-21.147 7.552a6.98 6.98 0 01-2.353.408z"></path>
      </g>
    </svg>
  );
}

export default Icon;
