import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 29"
      width="27"
      height="29"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth="2"
          transform="translate(-357 -94)"
        >
          <g transform="translate(358 95)">
            <path d="M24.462 24.593l-6.123-6.684a11.194 11.194 0 002.437-7.008C20.776 4.891 16.116 0 10.388 0S0 4.89 0 10.901s4.66 10.901 10.388 10.901c2.15 0 4.2-.68 5.951-1.972l6.17 6.734c.258.281.605.436.977.436.352 0 .685-.14.939-.397a1.474 1.474 0 00.037-2.01zM10.388 2.843c4.234 0 7.678 3.615 7.678 8.058s-3.444 8.058-7.678 8.058S2.71 15.344 2.71 10.9s3.444-8.057 7.678-8.057z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
