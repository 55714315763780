import React from "react";
import queryString from "query-string";
import { useLocation, useRouteMatch } from "react-router-dom";

import AnimatePresence from "../util/AnimatePresence";

import AgendaList from "../pages/AgendaList";
import AgendaCalendar from "../pages/AgendaCalendar";

import PageHeading from "../components/PageHeading";
import GridListToggle from "../components/GridListToggle";
import AgendaDetailPage from "../pages/AgendaDetail";

export default ({ onFetchError }) => {
  const location = useLocation();
  const match = useRouteMatch("/agenda/:gridOrList");

  const parsedSearchQuery = queryString.parse(location.search);
  const showEvent = !!parsedSearchQuery.event;
  let eventId;
  if (showEvent) {
    eventId = parsedSearchQuery.event;
  }

  return (
    <>
      <AnimatePresence>
        {showEvent && <AgendaDetailPage eventId={eventId} />}
      </AnimatePresence>
      <PageHeading
        Button={() => (
          <GridListToggle toList={`/agenda/list`} toGrid={`/agenda/grid`} />
        )}
      >
        Agenda
      </PageHeading>
      {match.params.gridOrList === "grid" ? (
        <AgendaCalendar onFetchError={onFetchError} />
      ) : (
        <AgendaList onFetchError={onFetchError} />
      )}
    </>
  );
};
