import React from "react";

import LecturerListItem from "../components/LecturerListItem";
import PageWrapper from "../components/PageWrapper";

export default ({ allLecturers }) => {
  return (
    <>
      <PageWrapper>
        {allLecturers.map(
          ({
            id,
            vorname,
            nachname,
            picurl,
            tutorrolle,
            hasUnansweredFeedback,
          }) => {
            const name = vorname + " " + nachname;
            const role = !!tutorrolle && tutorrolle[0];
            return (
              <LecturerListItem
                badgeCount={hasUnansweredFeedback ? 1 : 0}
                to={`/feedback/lecturer/${id}`}
                key={id}
                id={id}
                name={name}
                avatarImage={picurl}
                moduleName={role}
              />
            );
          }
        )}
      </PageWrapper>
    </>
  );
};
