import React from "react";
import styled from "styled-components";
import NewsPageWrapper from "../components/NewsPageWrapper";
import CopyToCliboardButton from "../components/CopyToClipboardButton";
import moment from "moment";

const NewsWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.secondary03};
  padding: 16px 16px 1px 16px;
  border-radius: ${(props) => props.theme.borders.radius01};
`;

const NewsHeader = styled.div``;

const CanteenDay = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary02};
`;

const FoodDescription = styled.div`
  line-height: 1.5;
  margin-left: -20px;
`;

export default ({ date, content }) => {
  const formattedDate = moment.unix(date).format("dddd, Do MMMM YYYY");
  const strippedContent = content.replace(/(<([^>]+)>)/gi, "");

  return (
    <NewsPageWrapper>
      <NewsWrapper>
        <CopyToCliboardButton
          text={`${formattedDate}:
${strippedContent}`}
        />
        <NewsHeader>
          <CanteenDay>{formattedDate}</CanteenDay>
        </NewsHeader>
        <FoodDescription dangerouslySetInnerHTML={{ __html: content }} />
      </NewsWrapper>
    </NewsPageWrapper>
  );
};
