import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;

const Input = styled.input`
  width: calc(100% - 24px);
  background: ${(props) => props.theme.colors.secondary02};
  color: ${(props) => props.theme.colors.primary};
  font-size: 17px;
  margin: 2rem auto;
  padding: 14px 24px;
  border-radius: 26px;
  outline: 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  &:focus {
    border-color: ${(props) => props.theme.colors.primary02};
  }
`;

const PasswordInput = styled(Input)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PasswordInput2 = styled(Input)`
  margin-top: 0rem;
  margin-bottom: 1rem;
`;

const SubmitButton = styled.input`
  color: ${(props) => props.theme.colors.primary};
  font-size: 10pt;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid ${(props) => props.theme.colors.accent};
  border-radius: 26px;
  background-color: ${(props) => props.theme.colors.accent};
  padding: 1rem 3rem;
  margin-top: 2rem;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease, opacity 200ms ease;
  &:disabled {
    background-color: transparent;
    opacity: 0.6;
  }
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.accent};
  margin: 1rem 1rem 0rem 1rem;
  text-align: left; 
  hyphens: auto; 
  line-height: 1.5; 
`;

export default ({ onSubmit, errorMessage }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isSubmitDisabled = password.length <= 3 || password !== confirmPassword;
  return (
    <Wrapper>
      <form autoComplete="off" onSubmit={(e) => onSubmit({ e, password })}>
        <p>Bitte gib hier dein neues Passwort ein.</p>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <PasswordInput
          required
          type="password"
          placeholder="Passwort"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <br />
        <PasswordInput2
          required
          type="password"
          placeholder="Passwort bestätigen"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
        />
        <br />
        <SubmitButton
          disabled={isSubmitDisabled}
          type="submit"
          value="Weiter"
          placeholder="Passwort"
        />
      </form>
    </Wrapper>
  );
};
