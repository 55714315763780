import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
      overscroll-behavior: none;
      color: ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.secondary};
  }

  * {
    user-select: none;
  }

  .selectable,
  .selectable * {
    user-select: all;
  }


  h3,
  h4,
  h5,
  h6 {
    margin-top: 2em;
    margin-bottom: .5rem;
    color: ${(props) => props.theme.colors.primary02};
  }

  p {
    line-height: 1.5;
  }


`;
