import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";

import ShareIcon from "../components/icons/Share";

const ShareButton = styled.div`
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: auto;
  right: 16px;
  top: 16px;
  position: absolute;
  color: ${(props) => props.theme.colors.primary05};
`;

export default ({ text }) => {
  return (
    <CopyToClipboard
      format="text/html"
      onCopy={() => alert("In die Zwischenablage kopiert.")}
      text={text}
    >
      <ShareButton>
        <ShareIcon />
      </ShareButton>
    </CopyToClipboard>
  );
};
