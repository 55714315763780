import React, { useState } from "react";
import styled from "styled-components";

import FeedbackQuestionWrapper from "../components/FeedbackQuestionWrapper";
import SubmitButton from "../components/FeedbackSubmitButton";

const GreyText = styled.div`
  color: ${(props) => props.theme.colors.primary02};
  font-weight: 700;
`;

const RegularText = styled.div`
  padding-top: 10px;
`;

const FeedbackButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;

const FeedbackYesButton = styled.input`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  border-top-left-radius: ${(props) => props.theme.borders.radius01};
  border-bottom-left-radius: ${(props) => props.theme.borders.radius01};
  background-color: ${(props) =>
    props.active ? props.theme.colors.accent : props.theme.colors.secondary05};
  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;

const FeedbackNoButton = styled.input`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
  outline: none;
  border: none;
  border-top-right-radius: ${(props) => props.theme.borders.radius01};
  border-bottom-right-radius: ${(props) => props.theme.borders.radius01};
  background-color: ${(props) =>
    props.active ? props.theme.colors.accent : props.theme.colors.secondary05};
  display: flex;
  justify-content: center;
  padding: 16px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease;
  &:disabled {
    background-color: ${(props) => props.theme.colors.accent};
  }
`;

export default ({ onSubmit, question, isLastQuestion }) => {
  const [selectedResult, setSelectedResult] = useState(null);
  const [initialStateChanged, setInitialStateChanged] = useState(false);
  const isYesActive = selectedResult !== null && selectedResult;
  const isNoActive = selectedResult !== null && !selectedResult;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit({ result: selectedResult });
      }}
    >
      <FeedbackQuestionWrapper>
        <GreyText>Stimmen Sie folgender Aussage zu?</GreyText>
        <RegularText>{question.question}</RegularText>
        <FeedbackButtonWrapper>
          <FeedbackYesButton
            onClick={() => {
              setSelectedResult(true);
              setInitialStateChanged(true);
            }}
            autoFocus
            type="button"
            value="ja"
            placeholder="ja"
            active={isYesActive}
          />
          <FeedbackNoButton
            onClick={() => {
              setSelectedResult(false);
              setInitialStateChanged(true);
            }}
            type="button"
            value="nein"
            placeholder="nein"
            active={isNoActive}
          />
        </FeedbackButtonWrapper>
      </FeedbackQuestionWrapper>
      <SubmitButton
        isLastQuestion={isLastQuestion}
        disabled={!initialStateChanged}
      />
    </form>
  );
};
