import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

import Modal from "../components/Modal";
import Lecturer from "../components/Lecturer";
import LoadingIndicator from "../components/LoadingIndicator";

const lecturerQuery = gql`
  query($lecturerId: String!) {
    getMyTutors(id: $lecturerId) {
      tutors {
        id
        tutorrolle
        studiengang
        anrede
        vorname
        nachname
        email
        tel
        web
        company
        picurl
      }
      feedbacks {
        tutor
        feedback
        isAnswered
      }
    }
  }
`;

export default ({ allLecturers }) => {
  const { lecturerId } = useParams();

  const { loading, error, data } = useQuery(lecturerQuery, {
    variables: { lecturerId },
  });

  const isRecessed = !!useRouteMatch("/feedback/lecturer/:lecturerId/feedback");

  if (loading) {
    return (
      <Modal
        modalId={lecturerId}
        recessed={isRecessed}
        closeButtonTo={"/feedback"}
      >
        <LoadingIndicator />
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal
        modalId={lecturerId}
        recessed={isRecessed}
        closeButtonTo={"/feedback"}
      >
        Dozent nicht gefunden
      </Modal>
    );
  }

  const lecturer = data.getMyTutors.tutors[0];
  const feedback = data.getMyTutors.feedbacks[0];

  lecturer.hasFeedback = !feedback.isAnswered;

  if (!!allLecturers) {
    const currentLecturer = allLecturers.find((l) => l.id === lecturer.id);
    if (!!currentLecturer) {
      lecturer.hasFeedback = currentLecturer.hasUnansweredFeedback;
    }
  }

  return (
    <Modal
      modalId={lecturerId}
      recessed={isRecessed}
      closeButtonTo={"/feedback"}
    >
      <Lecturer lecturer={lecturer} feedbackLink="/" />
    </Modal>
  );
};
